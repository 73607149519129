import Vue from "vue";
import Router from "vue-router";
import JwtService from "@/common/services/jwt.service";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  },
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/pages/auth/Login"),
    },
    {
      path: "/",
      component: () => import("@/views/theme/Layout"),
      beforeEnter: (to, from, next) => {
        if (!JwtService.getUser()) {
          next({ name: "login" });
        } else if (to.path === "/" || !to.path) {
          next({ name: "ListFiles" });
        }

        next();
      },
      children: [
        {
          path: "/files",
          component: () => import("@/views/pages/files/Index"),
          children: [
            {
              path: "/",
              name: "ListFiles",
              component: () => import("@/views/pages/files/List"),
            },
          ],
        },
        {
          path: "/users",
          component: () => import("@/views/pages/users/Index"),
          children: [
            {
              path: "/",
              name: "ListUsers",
              component: () => import("@/views/pages/users/List"),
            },
          ],
        },
      ],
    },
  ],
});
