import ApiService from "@/common/services/api.service";
import _ from "lodash";

// action types
export const GET_FILES = "getFiles";
export const GET_FILE = "getFile";
export const CREATE_FILE = "createFile";
export const UPDATE_FILE = "updateFile";
export const DELETE_FILE = "deleteFile";
export const RESET_FILE_ERRORS = "resetFileErrors";

// mutation types
export const SET_FILES = "setFiles";
export const SET_FILE = "setFile";
export const SET_ERRORS = "setErrors";
export const RESET_ERRORS = "resetErrors";

const state = {
  errors: {},
  files: [],
};

const getters = {
  files(state) {
    return state.files;
  },
  fileErrors(state) {
    return state.errors;
  },
};

const axiosConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const actions = {
  [GET_FILES](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("files/manual-upload", payload)
        .then((response) => {
          const files = response.data.data;

          context.commit(SET_FILES, files);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
  [GET_FILE](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`files/manual-upload/${id}`)
        .then((response) => {
          const file = response.data.data;

          resolve(file);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [CREATE_FILE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("files/manual-upload", payload, axiosConfig)
        .then((response) => {
          const file = response.data.data;

          context.commit(SET_FILE, file);
          context.commit(RESET_ERRORS);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
  [UPDATE_FILE](context, payload) {
    payload.append("_method", "PUT");

    return new Promise((resolve, reject) => {
      ApiService.post(
        `files/manual-upload/${payload.get("id")}`,
        payload,
        axiosConfig
      )
        .then((response) => {
          const file = response.data.data;

          context.commit(UPDATE_FILE, file);
          context.commit(RESET_ERRORS);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(SET_ERRORS, response.data.errors);
          reject(response);
        });
    });
  },
  [DELETE_FILE](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`files/manual-upload/${id}`)
        .then((response) => {
          context.commit(DELETE_FILE, id);
          resolve(response);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [RESET_FILE_ERRORS](context) {
    return new Promise((resolve) => {
      context.commit(RESET_ERRORS);
      resolve();
    });
  },
};

const mutations = {
  [SET_FILES](state, files) {
    state.files = files;
  },
  [SET_FILE](state, file) {
    state.files.push(file);
  },
  [UPDATE_FILE](state, file) {
    let index = state.files.findIndex((f) => f.id === file.id);

    state.files.splice(index, 1, file);
  },
  [DELETE_FILE](state, id) {
    let index = _.findIndex(state.files, (file) => file.id == id);
    state.files.splice(index, 1);
  },
  [SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_ERRORS](state) {
    state.errors = {};
  },
};

export const files = {
  getters,
  state,
  actions,
  mutations,
};
