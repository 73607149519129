import Vue from 'vue';
import Vuex from 'vuex';

import { auth } from '@/store/auth.module';
import { files } from '@/store/files.module';
import { users } from '@/store/users.module';

Vue.use(Vuex);


export const store = new Vuex.Store({
    modules: {
        auth,
        files,
        users
    }
});